<template>
  <div>
    <loading v-if="showLoading"></loading>
    <div v-if="showSuccess" class="container mt-5">
      <div class="p-3">
        <b-card class="mt-3" :img-src="avatarUrl" img-alt="Image" img-top>
          <div class="text-center font-weight-bolder">
            <h3>{{ lineData.displayName }}</h3>
          </div>
        </b-card>

        <div class="text-center mt-3">
          <h5>帳號綁定成功</h5>
        </div>
        <div class="text-center">
          <b-button @click="cancel" variant="success" size="lg" class="mb-1 mt-3">
            {{ redirectText }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import liff from "@line/liff";
import crmApi from "@/apis/liff/v2/crm";

export default {
  data() {
    return {
      showLoading: true,
      showSuccess: false,
      staffId: null,
      redirectUri: null,
      redirectText: null,
      lineData: {},
      token: null,
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    avatarUrl() {
      return (
        this.lineData.pictureUrl ??
        "https://via.placeholder.com/500x500.png?text=Empty"
      );
    },
  },
  methods: {
    async init() {
      this.lineData = await liff.getProfile();
      this.token = await liff.getAccessToken();
      this.staffId = this.$route.query.staff_id;
      this.redirectUri = this.$route.query.redirect_uri;
      this.redirectText = this.$route.query.redirect_text || '回官方帳號';

      await this.submit();
    },
    async submit() {
      crmApi.staffBinding(this.staffId)
        .then(() => {
          this.showLoading = false;
          this.showSuccess = true;
        })
        .catch((result) => {
          this.showLoading = false;
          this.$swal.fire({
            type: "error",
            title: "綁定失敗",
            html: result.message,
          });
        });
    },
    async cancel() {
      let oaUrl = this.redirectUri || this.liffInfo.line_oa_url;
      if (oaUrl) {
        window.location.href = oaUrl;
      } else {
        liff.closeWindow();
      }
    },
  }
}
</script>
